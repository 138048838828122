import { boot } from 'quasar/wrappers'
import { Notify } from 'quasar'

// "async" is optional;
// more info on params: https://v2.quasar.dev/quasar-cli/boot-files
function NotifyInit() {
  Notify.setDefaults({
    position: 'top',
  })

  Notify.registerType('error', {
    icon: 'warning',
    progress: true,
    classes: 'notify-error',
    timeout: 2500,
  })

  Notify.registerType('sucess', {
    icon: 'check_circle',
    progress: true,
    classes: 'notify-sucess',
    timeout: 2500,
  })

  Notify.registerType('alert', {
    icon: 'warning',
    progress: true,
    classes: 'notify-alert',
    timeout: 2500,
  })
}

const NotifyError = (text, opts) =>
  Notify.create({ type: 'error', message: text, ...opts })

const NotifySucess = (text, opts) =>
  Notify.create({
    icon: 'check_circle',
    progress: true,
    classes: 'notify-sucess',
    timeout: 2500,
    message: text,
    ...opts,
  })

const NotifyAlert = (text, opts) =>
  Notify.create({ type: 'alert', message: text, ...opts })

export { NotifyError, NotifySucess, NotifyAlert, NotifyInit }
