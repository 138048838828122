import { colors, copyToClipboard, date, is, Notify } from 'quasar'
import { gsap } from 'gsap'
import { NotifyError, NotifySucess } from 'js/vue/boot/Notify'
import { unref } from 'vue'

const GLOBAL = {
  URLSearch: new URLSearchParams(window.location.search),

  screen: {
    md: () => window.matchMedia('(max-width: 1180px)').matches,
  },

  debounce: (time, fn, name) => {
    return (...args) => {
      clearTimeout(window[name])
      window[name] = setTimeout(() => {
        fn(...args)
      }, time)
    }
  },

  enableDragScroll: (removeEvents) => (e, options) => {
    const domElement = e.currentTarget
    const target = e.target
    const offset = { x: 0, y: 0 }
    const startPos = { x: 0, y: 0 }

    const onMouseMove = (e) => {
      // console.log('onMouseMove')
      if (removeEvents.value) {
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseup', onMouseUp)
      }

      domElement.scrollLeft = offset.x + startPos.x - e.clientX
      domElement.scrollTop = offset.y + startPos.y - e.clientY
      return false
    }

    const onMouseUp = (e) => {
      // console.log('onMouseUp')
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)

      return false
    }

    const onMouseDown = (e) => {
      // console.log('onMouseDown')
      removeEvents.value = false
      if (e.button !== 0 || options?.fxIgnore(e)) return

      startPos.x = e.clientX
      startPos.y = e.clientY
      offset.x = domElement.scrollLeft
      offset.y = domElement.scrollTop

      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)

      return false
    }

    domElement.addEventListener('mousedown', onMouseDown)
  },

  ordenateKey: (key) => (a, b /* sort cb */) => {
    if (a[key] > b[key]) return 1
    if (a[key] < b[key]) return -1
    return 0
  },

  setHeightInCol: (
    wrapper = '.cards-wrapper',
    item = '.kanban-card',
    space = 12
  ) => {
    
    document.querySelectorAll(wrapper).forEach((i) => {
      const filhos = [...i.querySelectorAll(item)]
      const gap = filhos.length * space
      
      const height = filhos.reduce((acc, children) => {
        acc += children.getBoundingClientRect().height
        return acc
      }, 0)

      /*     const createCard = i
        .closest('.kanban-col')
        .querySelector('.kanban-new-card')

      const newCardSize = createCard
        ? createCard.getBoundingClientRect().height
        : 0
 */
      const heightWithGap = (height /* + newCardSize */ + gap + 16).toFixed(0)
      gsap.to(i, {
        height: heightWithGap,
        duration: 0.5,
        ease: 'power1',
      })
    })
  },

  async copyAndAlert(text) {
    try {
      await copyToClipboard(text)
      Notify.create({
        icon: 'check_circle',
        progress: true,
        classes: 'notify-sucess',
        timeout: 2500,
        message: 'Copiado com Sucesso',
      })
    } catch (e) {
      console.log(e)
      NotifyError('Erro ao Copiar')
    }
  },

  FData(value, format = 'DD/MM/YY') {
    if (date.isValid(value)) {
      const dataFormatted =
        value.length > 10 ? value : String(value).replace('-', '/')
      const data = date.formatDate(dataFormatted, format, {
        days: [
          'Domingo',
          'Segunda',
          'Terça',
          'Quarta',
          'Quinta',
          'Sexta',
          'Sábado',
        ],
      })
      return data
    } else {
      return '-'
    }
  },

  FMoney(value, opts) {
    const val = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      ...opts,
    }).format(value)

    return val.toString() === 'NaN' ? '-' : val
  },

  removeHTMLFromString(v) {
    return v.replaceAll(/<[^>]*>/g, '')
    // .replaceAll('&nbsp;', ' ')
  },
  isEmpty(obj) {
    if (!is.object(obj)) return true
    return Object.keys(obj).length === 0
  },

  FDataAndTime(value, format = 'DD/MM/YYYY - HH:mm', text = 'às') {
    if (date.isValid(value)) {
      const dataFormatted = value.length > 10 ? value : value.replace('-', '/')
      const data = date.formatDate(dataFormatted, format)
      const time = date.formatDate(dataFormatted, 'HH:mm')
      console.log(data, time)
      return `${data}`
    } else {
      return '-'
    }
  },

  compareAndReturnDiff: (oldv = {}, newv = {}) => {
    const modificado = {}
    if (!oldv || !newv) return null

    Object.entries(unref(newv)).forEach(([key, v]) => {
      const a = oldv[key]
      const b = newv[key]
      if (is.deepEqual(a, b)) return null
      else modificado[key] = v
    })
    return Object.keys(modificado)?.length ? modificado : null
  },

  generateStringFilterFromObject(obj, x) {
    const filtros = Object.entries(obj).map(
      ([key, value]) => `&${key?.trim()}=${value || ''}`
    )
    return x ? filtros.join('') : filtros.join('').replace('&', '?')
  },

  generateObjFilter() {
    const params = {}
    for (const [key, value] of new URLSearchParams(
      window.location.search
    ).entries()) {
      params[key] = value
    }
    return params
  },

  isEmptyData(data) {
    const result = {}
    if (!data) {
      result.isEmpty = true
    } else if (Array.isArray(data) && data.length === 0) {
      result.isEmpty = true
    } else if (
      typeof data === 'object' &&
      Object.keys(data).length === 0 &&
      data.constructor === Object
    ) {
      result.isEmpty = true
    } else {
      result.isEmpty = false
    }
    result.isArray = Array.isArray(data)
    result.isObject = is.object(data)

    return result
  },

  async blobDownloadFile(url, fileName) {
    const image = await fetch(url)
    const imageBlob = await image.blob()
    const imageURL = URL.createObjectURL(imageBlob)

    const anchor = document.createElement('a')
    anchor.href = imageURL
    anchor.download = fileName

    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)

    URL.revokeObjectURL(imageURL)
  },

  calcularDiferenca(data) {
    const dataAtual = new Date()
    const dataRecebida = new Date(data)
    const diferencaEmMS = dataAtual - dataRecebida
    const diferencaEmSegundos = diferencaEmMS / 1000
    const diferencaEmMinutos = diferencaEmSegundos / 60
    const diferencaEmHoras = diferencaEmMinutos / 60

    if (diferencaEmMinutos < 1) {
      return 'Agora'
    } else if (diferencaEmHoras < 1) {
      return `${Math.floor(diferencaEmMinutos)}m atrás`
    } else if (diferencaEmHoras < 24) {
      return `${Math.floor(diferencaEmHoras)}h e ${Math.floor(
        diferencaEmMinutos % 60
      )}m atrás`
    } else {
      const diferencaEmDias = diferencaEmHoras / 24
      return `${Math.floor(diferencaEmDias)}d e ${Math.floor(
        diferencaEmHoras % 24
      )}h atrás`
    }
  },
  newUrlImg(url) {
    return new URL(`${window.caminhoEstatico}${url}`, import.meta.url).href


  },

  // getAsset: (url) =>,

  returnRGB: (v) => (v ? `${Object.values(colors.hexToRgb(v))}` : ''),

   unique(value, index, self) { 
    return self.indexOf(value) === index;
}

}

export default GLOBAL

// window.GLOBAL = GLOBAL;
